* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  margin: 0;
  display: flex;
	flex-direction: column;
	flex: 1;
	min-height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root,
.App {
  display: inherit;
  flex-direction: inherit;
  flex: inherit;
  min-height: inherit;
}

main {
  flex: 1;
  padding: 2rem 0;
  display: flex;
}

main > .MuiContainer-root:first-child > .MuiList-root:first-child {
  margin-top: -2rem;
  padding-top: 0;
}
